import * as types from "./mutation-types";
import { getUserAttributes, getUserGroups, mapUser } from "../../utils/auth-helper.js";

export const actions = {
    [types.AUTH_LOGOUT]: async ({ commit }, skipRedirect) => {
        try {
            commit(types.AUTH_LOGOUT);
            localStorage.clear();
            sessionStorage.clear();

            commit("appointments/RESET_STATE", null, { root: true });
            commit("patients/PATIENTS_RESET", null, { root: true });
            commit("practitioners/RESET", null, { root: true });
            commit("messaging/RESET", null, { root: true });
            commit("tasks/RESET_STATE", null, { root: true });

            try {
                await post("admin/logout");
            } catch (err) {
                console.warn("Logout API call failed:", err);
            }

            if (!skipRedirect) {
                // Use a small timeout to ensure store mutations complete
                setTimeout(() => {
                    window.location.href = "/userlogin?r=" + Math.random();
                }, 100);
            }
        } catch (error) {
            console.error("Logout error:", error);
            // Still redirect on error to ensure user is logged out
            if (!skipRedirect) {
                window.location.href = "/userlogin?r=" + Math.random();
            }
        }
    },
    [types.SET_USER]: async ({ commit, dispatch }, cognitoUser) => {
        if (!cognitoUser) {
            commit(types.SET_USER_FAILURE, "No user provided");
            return;
        }
        commit(types.SET_USER, cognitoUser);
        const attrs = await getUserAttributes(cognitoUser);
        cognitoUser.attributes = attrs || cognitoUser.attributes;
        let user = mapUser(cognitoUser);
        user.groups = await getUserGroups(cognitoUser);
        localStorage.setItem("user", JSON.stringify(user));

        commit(types.SET_USER_SUCCESS, user);
    },
    [types.UPDATE_SETTINGS]: async ({ commit, state, dispatch }, settings) => {
        commit(types.UPDATE_SETTINGS, settings);
        let user = state.user;
        user.settings = settings;
        commit(types.UPDATE_SETTINGS_SUCCESS, user);
        localStorage.setItem("user", JSON.stringify(user));
    },
};
