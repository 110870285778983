import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as appMutations from "../appMutations";
import * as patientMutations from "../patients/mutation-types";
import { getCachedObject } from "../../utils/helpers";

export const actions = {
  [types.CREATE_USER]: async ({ commit, state, dispatch }, { user, admin }) => {
    commit(types.CREATE_USER, user);
    try {
      var url = admin ? "admin/adminuser" : "user";
      const resp = await api
        .post(url, {
          userID: user.email,
          patientID: user.patientID,
          password: user.password,
          name: user.name,
          groups: user.groups,
          instanceID: user.instanceID,
          address: user.address,
          citizen: user.citizen,
          phones: user.phones,
          phone: user.phone,
          emailNotifications: user.emailNotifications,
          smsNotifications: user.smsNotifications,
        })
        .catch(error => {
          throw new Error(error);
        });
      if (!resp) {
        commit(types.CREATE_USER_FAILURE, "An error has occurred. Please try again or contact support.");
        dispatch(appMutations.SNACKBAR_ERROR, "An error has occurred. Please try again or contact support.", {
          root: true,
        });
        return;
      }
      commit(types.CREATE_USER_SUCCESS, mapUser(resp, state.roles));
    } catch (error) {
      var errorMessage = error.message;
      if (errorMessage == "EMAIL_EXISTS") {
        errorMessage = "An account with this email already exists.";
      } else {
        errorMessage = "An error has occurred. Please try again or contact support.";
      }
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
      commit(types.CREATE_USER_FAILURE, errorMessage);

      throw error;
    }
  },
  [types.UPDATE_USER]: async ({ commit, dispatch }, { user, notify }) => {
    try {
      if (!user) return;
      commit(types.UPDATE_USER, user);
      //ensure the user.patients array only contains unqique non-null values
      user.patients = [...new Set(user.patients.filter(p => p?.length > 0))];
      let result = await api.client.put("user/" + user.userID, user).catch(error => {
        throw error.response?.data;
      });
      const newUser = { ...user, ...result.data };
      commit(types.UPDATE_USER_SUCCESS, newUser);
      commit("patients/" + patientMutations.UPDATE_RELATED_PERSON, newUser, { root: true });

      var currentUser = getCachedObject("user");
      if (currentUser.userID == user.userID) {
        localStorage.setItem("user", JSON.stringify(user));
        //set the auth store user state
        dispatch("auth/UPDATE_SETTINGS", newUser.settings, { root: true });
      }
      if (notify) {
        dispatch(appMutations.SNACKBAR_SUCCESS, "User Updated", {
          root: true,
        });
      }
    } catch (error) {
      commit(types.UPDATE_USER_FAILURE, error);
      if (notify) {
        dispatch(appMutations.SNACKBAR_ERROR, "Error Updating User", {
          root: true,
        });
      }
      throw error;
    }
  },
  [types.DELETE_USER]: async ({ commit, dispatch }, user) => {
    try {
      commit(types.DELETE_USER);
      await api.client.delete("user/" + user.userID);
      commit(types.DELETE_USER_SUCCESS, user.userID);
      dispatch(appMutations.SNACKBAR_SUCCESS, "User Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_USER_FAILURE);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting User", {
        root: true,
      });
      throw error;
    }
  },
  [types.LOAD_ADMIN_USERS]: async ({ commit, state }, groups) => {
    commit(types.LOAD_ADMIN_USERS);
    try {
      groups = groups ?? ["Orbital-Platform-Admin"]
      var query = "?groups=" + groups.join("&groups=");
      var result = await api.get("user/" + query);

      var users = result?.data?.entry.map(user => {
        return mapUser(user.resource, state.roles);
      });
      commit(types.LOAD_ADMIN_USERS_SUCCESS, users);
    } catch (error) {
      commit(types.LOAD_ADMIN_USERS_FAILURE, error);
      throw error;
    }
  },
  [types.LOAD_USER]: async ({ commit, state }, userID) => {
    commit(types.LOAD_USER);
    try {
      var result = await api.get("user/" + userID);
      var user = mapUser(result.data, state.roles);
      commit(types.LOAD_USER_SUCCESS, user);
    } catch (error) {
      commit(types.LOAD_USER_FAILURE, error);
      throw error;
    }
  },
  [types.CHECK_USERNAME_AVAILABILITY]: async ({ commit }, userID) => {
    commit(types.CHECK_USERNAME_AVAILABILITY, userID);
    if (userID) {
      var result = await api.post("ValidateUserName", { userID: userID });
      commit(types.CHECK_USERNAME_AVAILABILITY_COMPLETE, result);
    }
  },
  [types.LOAD_USER_FILTERS]: ({ commit }) => {
    commit(types.LOAD_USER_FILTERS);
    try {
      var filters = window.localStorage.getItem("filters");
      commit(types.LOAD_USER_FILTERS_SUCCESS, JSON.parse(filters));
    } catch (error) {
      console.log(error);
    }
  },
  [types.UPDATE_USER_FILTERS]: ({ commit }, filters) => {
    window.localStorage.setItem("filters", JSON.stringify(filters));
    commit(types.UPDATE_USER_FILTERS_SUCCESS, filters);
  },
  [types.RESET_USER]: ({ commit }) => {
    commit(types.RESET_USER);
  },
};

function mapUser(cogUser, roles) {
  if (!cogUser) return;
  //user.locked = isLocked(cogUser);

  return { ...cogUser, role: cogUser.role ?? mapRoles(cogUser.groups, roles) };
}

function mapRoles(groups, roles) {
  if (!groups) return "Portal User";
  return groups.length == 0
    ? "Portal User"
    : groups.map(g => {
      const role = roles?.find(r => r.group == g || r.name == g);
      return role ? role.name : null;
    })[0]; //returning first one
}
