<template>
  <v-app :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
    <v-banner v-if="showUpdateBanner" single-line static style="z-index: 99999; position: absolute" class="bg-primary text-white">
      <v-icon icon="mdi-lock" color="white" size="40">mdi-alert-circle</v-icon>
      There is a new update available. Please refresh your browser.
      <template #actions>
        <v-btn variant="text" class="text-white" @click="snooze">Snooze</v-btn>
        <v-btn variant="text" class="text-white" @click="reload">Refresh</v-btn>
      </template>
    </v-banner>
    <v-banner v-if="showBrowserBanner" single-line sticky style="z-index: 99" class="bg-primary text-white">
      <v-icon icon="mdi-lock" color="white" size="40">mdi-alert</v-icon>
      <span class="text-h6">Browser Not Supported. For a better experience please try a modern browser such as Microsoft Edge, Google Chrome, Firefox, or Opera.</span>
    </v-banner>
    <v-banner v-if="showBanner" single-line sticky style="z-index: 99" class="bg-red text-white">
      <v-icon icon="mdi-lock" color="white" size="40">mdi-alert</v-icon>
      <span class="text-h6">{{ banner.message }}</span>
    </v-banner>
    <span v-if="showSystemNotifications" sticky>
      <v-banner v-for="notification in notifications" :key="notification.message" single-line style="z-index: 99" class="bg-red text-white">
        <v-icon icon="mdi-lock" color="white" size="40">mdi-alert</v-icon>
        <span class="text-h6">{{ notification.message }}</span>
        <template #actions="{ dismiss }">
          <v-btn variant="text" color="white" @click="dismiss">Dismiss</v-btn>
        </template>
      </v-banner>
    </span>
    <Drawer v-if="$route.meta.drawer" order="0" />
    <PortalDrawer v-else-if="$route.meta.PortalDrawer" />
    <v-main id="v-main">
      <RouterView v-slot="{ Component }">
        <Component :is="Component" />
      </RouterView>
    </v-main>
    <!-- <v-btn icon @click="controlPanelDrawer = !controlPanelDrawer" absolute>
      <v-icon>{{ !controlPanelDrawer ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
    </v-btn>
    <v-navigation-drawer v-if="showControlPanel" right :rail.sync="controlPanelDrawer" dark
      rail-width="32">
      <v-btn icon @click="controlPanelDrawer = !controlPanelDrawer" absolute>
        <v-icon>{{ !controlPanelDrawer ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
      </v-btn>
      <AmazonConnect v-show="!controlPanelDrawer" style="height: 100%; overflow-y: auto;"></AmazonConnect>
    </v-navigation-drawer> -->
    <IdleModal v-if="showIdleWarning" />

    <v-snackbar v-model="showSnackbar" :timeout="snackbar.timeout || 6000">
      {{ snackbar.message }}

      <template #actions>
        <v-btn theme="dark" variant="text" @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="psnackbar.show" :color="psnackbar.color" :timeout="psnackbar.timeout">
      {{ psnackbar.message }}
    </v-snackbar>
    <span id="version" style="display: none">{{ version }}</span>
  </v-app>
</template>

<script setup>
import { useSkins } from "@core/composable/useSkins";
import { useTheme } from "vuetify";
import { useThemeConfig } from "@core/composable/useThemeConfig";
import { hexToRgb } from "@layouts/utils";

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl, handleSkinChanges } = useThemeConfig();

const { global } = useTheme();

const { layoutAttrs, injectSkinClasses } = useSkins();

injectSkinClasses();

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme();
syncConfigThemeWithVuetifyTheme();
handleSkinChanges();
</script>

<script>
import Drawer from "./components/Drawer.vue";
import PortalDrawer from "./components/PortalDrawer.vue";
import IdleModal from "./components/IdleModal.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { PATIENTS_RESET } from "./store/patients/mutation-types";
import { SNACKBAR_ERROR, SNACKBAR_INFO } from "./store/appMutations";
import { LOAD_SYSTEM_NOTIFICATIONS } from "./store/system-notifications/mutation-types";
import * as api from "./utils/api";
import { startIdleTimer } from "./utils/auth-helper";
import { provide } from "vue";
import { useSnackbarStore } from "@/store/snackbar";
import { storeToRefs } from "pinia";
import { useRoleStore } from "@/store/roles";
var roleStore;

//used for tab blinking
var oldTitle = document.title;
var timeoutId = null;
export default {
  name: "App",
  components: {
    Drawer,
    PortalDrawer,
    IdleModal,
    // AmazonConnect
  },
  inject: {
    version: "version",
    features: "features",
  },
  provide: {
    checkFeature(string) {
      if (!this?.organization?.features) return false;
      return this.organization?.features[string] ?? (this.organization?.features?.experimental && this.organization.features.experimental?.indexOf(string) > -1);
    },
    checkSetting(string) {
      return this.organization?.settings?.[string];
    },
    can(action, resource) {
      var roles = this.user?.groups.filter(g => this.user?.groups.includes(g));
      var pass = false;
      roles.forEach(role => {
        if (role.permissions?.includes(action)) {
          pass = true;
        }
      });

      return pass;
    },
    darkMode() {
      return this.$vuetify.theme.dark || this.$vuetify.theme.name == "dark";
    },
  },
  setup() {},
  data: () => ({
    drawer: false,
    controlPanelDrawer: true,
    showSnackbar: false,
    showBanner: false,
    showUpdateBanner: false,
    showBrowserBanner: false,
    unsubscribe: null,
    isAppIdle: false,
    psnackbar: useSnackbarStore(),
  }),
  watch: {
    isAppIdle(val) {
      if (val && this.user) {
        //blink titlebar if idle
        oldTitle = document.title == "Session Expiring" ? oldTitle : document.title;
        if (!timeoutId) {
          timeoutId = setInterval(function () {
            document.title = document.title == "Session Expiring" ? oldTitle : "Session Expiring";
          }, 1000);
        }
      } else {
        clearInterval(timeoutId);
        if (oldTitle) document.title = oldTitle;
        timeoutId = null;
      }
    },
  },
  created() {
    roleStore = useRoleStore();
    this.LOAD_SYSTEM_NOTIFICATIONS();
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) this.showBrowserBanner = true;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setSnackbar") {
        //hide then show to reset timer
        this.showSnackbar = false;
        this.showSnackbar = true;
      }
      if (mutation.type === "setBanner") {
        this.showBanner = true;
      }
      if (mutation.type === "clearBanner") {
        this.showBanner = false;
      }
      if (mutation.type === "auth/AUTH_LOGOUT") {
        this.PATIENTS_RESET();
      }
    });
    this.checkVersion();
    setInterval(this.checkVersion, 15000 * 60);
    //Idle timer listener
    startIdleTimer().subscribe(isIdle => {
      this.isAppIdle = isIdle;
    });
  },
  unmounted() {
    if (this.unsubscribe) this.unsubscribe();
  },
  computed: {
    ...mapState(["snackbar", "banner"]),
    ...mapState("auth", ["user"]),
    ...mapState("organization", ["organization"]),
    ...mapState("system_notifications", ["notifications"]),
    darkBackground() {
      return this.$route.meta.PortalDrawer || this.$route.meta.drawer;
    },
    showIdleWarning() {
      return this.user && this.isAppIdle;
    },
    showSystemNotifications() {
      return true;
    },
    showControlPanel() {
      return this.user && this.user?.groups?.includes("amazon-connect") && !this.user?.settings?.hideAmazonConnect && this.organization?.settings?.amazonConnectUrl;
    },
    roles() {
      return roleStore.roles;
    },
  },
  methods: {
    ...mapActions("patients", [PATIENTS_RESET]),
    ...mapActions("system_notifications", [LOAD_SYSTEM_NOTIFICATIONS]),
    ...mapActions([SNACKBAR_ERROR, SNACKBAR_INFO]),
    reload() {
      window.location.reload();
    },
    async checkVersion() {
      api.GetInit().then(async response => {
        if (this.version !== response?.data.version) {
          console.log("New version available. Prompting to update...");
          this.showUpdateBanner = true;
        }
      });
    },
    snooze() {
      this.showUpdateBanner = false;
      setInterval(this.checkVersion, 5000 * 60);
    },
  },
};
</script>

<style lang="scss">
@use "@core/scss/base/_index";

.ag-root-wrapper {
  border: none !important;
}

.ag-theme-quartz,
.ag-theme-quartz-dark {
  height: 100%;
  overflow: auto;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  box-shadow: none;
  border-color: transparent;
}

.ag-cell-task {
  display: flex;
  align-items: center;
}

.ag-cell-wrap {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  line-height: 20px;
}

.ag-status-bar {
  border-top: 1px solid #dddddd;
}

.bg-transparent {
  background-color: transparent !important;
}

.v-expansion-panel-title__overlay {
  background-color: transparent !important;
}

.v-card--variant-flat {
  background: inherit !important;
  color: inherit !important;
}
.admin-appbar {
  padding: 0 12px 0 12px !important;
}

.full-height {
  height: 100vh;
}

.v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 4px !important;
}

.v-list-item__icon.v-list-group__header__append-icon {
  min-width: 10px !important;
}

.showOverflow {
  overflow: initial !important;
}

.admin-tab-bar .v-tabs-bar {
  height: 64px;
}

.v-tab.v-tab {
  font-weight: bold;
}

/* This fixes the bad vertical alignment of table headers */
.v-data-table-header th {
  white-space: nowrap;
}

.shrink-x-small-loader .v-btn__loader .v-progress-circular.v-progress-circular--indeterminate {
  height: 16px !important;
}

.message-content {
  white-space: pre-wrap;
  overflow-x: auto;
}

.message-content p {
  line-height: 16pt;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-cursor input {
  caret-color: transparent;
}

.cardTitle {
  display: inline-block;
  cursor: pointer;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none !important;
  color: inherit !important;
  line-height: 1.5;
  font-size: 14px;
  padding-top: 3px;
}

.status-chip {
  max-width: 100px;
}

.status-name {
  max-width: 82px;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.assignee-chip {
  max-width: 105px;
}

.chip-name {
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 72px;
  text-overflow: ellipsis !important;
}

// .gradient-mask {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   height: 60px;
//   background-image: linear-gradient(to bottom, rgba(243, 245, 249, 0) 0%, #f3f5f9 100%);
// }

.filter-field {
  white-space: nowrap;
  max-width: 200px;
  width: 200px;
}

.filter-field-dropdown {
  width: 480px;
}

.flag-icon {
  max-height: 18px;
  max-width: 28px;
}

.full-height-below-nav {
  height: calc(100vh - 64px);
}

.full-width {
  width: 100%;
}

.custom-content h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Arial, sans-serif !important;
  font-size: 14px !important;
  color: #6b6b6b !important;
  font-weight: 400 !important;
}

.v-badge.v-badge--inline,
.v-badge.v-badge--inline .v-badge__wrapper {
  justify-content: space-between;
  width: 100%;
}

.v-tab .v-btn__content {
  text-transform: initial;
}

.elevation-0 .v-expansion-panel__shadow {
  display: none !important;
}

.inverted-image {
  filter: invert(100%);
}

.amplify-authenticator__tabs-wrapper {
  display: none !important;
}

.search-box {
  max-width: 300px;
}

.line {
  margin: 4px auto;
  height: 30px;
  width: 2px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><circle cx="5" cy="5" r="4" fill="%2390A4AE" /></svg>');
  background-repeat: repeat-y;
  animation: flow 1s linear infinite;
}

@keyframes flow {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 10px;
  }
}
.min-500 {
  min-height: 500px;
}
.min-250 {
  min-height: 250px;
}
.timeline-container {
  max-height: 60vh;
}
.text-strikethrough {
  text-decoration: line-through;
}
.modal-card-text {
  min-height: 500px;
  max-height: 50%;
  overflow: auto;
}
.provider-search-embdedded {
  max-height: 500px;
  overflow: auto;
}
.header-btn {
  text-transform: initial;
  font-weight: 600;
}
.width-50 {
  width: 50%;
}
.done {
  text-decoration: line-through;
  color: #ccc;
}

.custom-tooltip {
  opacity: 1 !important;
}

.attribute-field {
  min-width: 140px;
  max-width: 250px;
}

.attribute-field-wide {
  min-width: 200px;
  max-width: 400px;
}
.task-edit-contents {
  min-height: 520px;
  overflow: auto;
}
</style>
